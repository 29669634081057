import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { BrandThemes } from "../utils/brand-themes";

const LineBarChart = (props) => {
  let [state, updateState] = useState({
    dateRange: props.dateRange,
    label: props.label,
  });

  useEffect(() => {
    updateState({ ...state, dateRange: props.dateRange, label: props.label });
  }, [props]);

  const labels = [];

  let matchedNames = [];

  var lineBarDataSet = {
    type: "line",
    label: state.label,
    hoverBorderColor: BrandThemes.vibrantWave,
    hoverBorderWidth: 4,
    fill: false,
    data: [],
  };

  let entries = props.entries.filter((entry) => {
    let entryDate = new Date(entry.date);
    if (
      entryDate >= state.dateRange.startDate &&
      entryDate <= state.dateRange.endDate
    ) {
      return entry.name;
    }
  });

  const getAssociatedData = (entry, returnTargets) => {
    const data = entries.filter(
      (e) => e[props.secondFilter] === entry[props.secondFilter]
    );
    return returnTargets
      ? data.filter((e) => e.targetAccount === true).length
      : data.length;
  };

  const getDatasets = () => {
    const datasets = [];
    if (entries.length > 0) {
      let prevValues = [entries[0][props.secondFilter]];
      entries.forEach((entry, index) => {
        if (index === 0) {
          datasets.push({
            type: "bar",
            label: props.label,
            data: [getAssociatedData(entry)],
            backgroundColor: BrandThemes.vibrantIndigo,
            hoverBorderColor: BrandThemes.vibrantWave,
            hoverBorderWidth: 4,
            stack: index
          }); 
          datasets.push({
            type: "bar",
            label: `Target ` + props.label,
            data: [getAssociatedData(entry, true)],
            backgroundColor: BrandThemes.oceanWave,
            hoverBorderColor: BrandThemes.vibrantWave,
            hoverBorderWidth: 4,
            stack: index
          });
          entry[props.secondFilter] =
            entry[props.secondFilter].split(" ").length > 5
              ? "Invalid Value"
              : entry[props.secondFilter];

          labels.push(entry[props.secondFilter]);
        } else if (!prevValues.includes(entry[props.secondFilter])) {
          entry[props.secondFilter] =
            entry[props.secondFilter].split(" ").length > 5
              ? "Invalid Value"
              : entry[props.secondFilter];

          labels.push(entry[props.secondFilter]);
          datasets[0].data.push(getAssociatedData(entry));
          datasets[1].data.push(getAssociatedData(entry, true)); // TODO test this

          prevValues.push(entry[props.secondFilter]);
        }
      });
    } else {
      datasets.push({
        label: state.label,
        data: [],
      });
    }

    return datasets;
  };

  const chartData = {
    labels: labels,
    datasets: getDatasets(),
  };

  return (
    <Bar
      data={chartData}
      options={{
        legend: { position: "top", labels: { boxWidth: 0 } },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        //scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }, {stacked: true}] }, xAxes: [{stacked: true}],
      }}
    />
  );
};

export default LineBarChart;
