import React from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../utils/icons";

// let columns = [];

const EntryTable = (props) => {
  // let columns = []
  const columns = [
    {
      title: "Date",
      field: "date",
    },
    {
      title: "Event",
      field: "event",
      width: "60rem",
    },
    {
      title: "Company",
      field: "company",
    },
    {
      title: "Contact",
      field: "contact",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Target?",
      field: "targetAccount",
    },
  ];
  const optionalKeys = ["nextSteps"];
  // const excludedKeys = ["timestamp", "rawText", "userName", "office", "level", "tableData", "otherFins", "slackChannel"];
  if (props.entries.length > 0) {
    Object.keys(props.entries[0]).forEach((key) => {
      if (optionalKeys.includes(key)) {
        columns.push({
          title: key,
          field: key,
        });
        if (key === "event") {
          columns[columns.length - 1] = {
            ...columns[columns.length - 1],
            width: "60rem",
          };
        }
        // if (key === "date") {
        //     columns[columns.length - 1] = { ...columns[columns.length - 1] }
        // }
      }
    });
  }

  return (
    <MaterialTable
      options={{ tableLayout: "auto", filtering: true }}
      icons={tableIcons}
      columns={columns}
      data={props.entries.length > 0 ? props.entries.sort((a,b)=>new Date(b.date) - new Date(a.date)) : []} // not sure if sort needed here
      title={`Entries for the ${props.office} office`}
    />
  );
};

export default EntryTable;
