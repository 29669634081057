import React, { useEffect } from "react";
import { Box, Paper, Grid, Select, MenuItem, Typography } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { BrandThemes } from "../utils/brand-themes";
import { ONE_DAY_IN_MILLISECONDS } from "./../utils/constants";

const RenderLineChart = (props) => {
  useEffect(() => {
    
  }, [props.office]);

  let currentDate = new Date(new Date().setHours(0, 0, 0, 0));

  const getPeriodStartEndDates = (numPeriodsToCreate, periodType, currentDate) => {
    let startEndDatesByPeriod = new Map();
    if (periodType === "weeks") {
      const numDaysInWeek = 7;
      for (let i = numPeriodsToCreate; i > 0; i--) {
        startEndDatesByPeriod.set("week" + i.toString(), {
          startDate: new Date(new Date(currentDate - (ONE_DAY_IN_MILLISECONDS * ((i * numDaysInWeek) - 1))).setHours(0, 0, 0, 0)),
          endDate: i === 1 ? null : new Date(new Date(currentDate - (ONE_DAY_IN_MILLISECONDS * ((i - 1) * numDaysInWeek))).setHours(23, 59, 59, 0))
        })
      }
    } else if (periodType === "months") {
      const currentMonthStart = new Date(currentDate).setDate(1);
      const currentMonth = currentDate.getMonth();
      for (let i = numPeriodsToCreate; i > 0; i--) {
        const startDate = new Date(new Date(currentMonthStart).setMonth(currentMonth - (i - 1)));
        startEndDatesByPeriod.set("month" + i.toString(), {
          startDate: startDate,
          endDate: i === 1 ? null : new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth() + 1, 0)
        })
      }
    }
    return startEndDatesByPeriod;
  };

  const getDataLabels = (periodStartEndDates) => {
    const dataLabels = [];
    periodStartEndDates.forEach((key) => {
      dataLabels.push(`${key.startDate.toLocaleDateString().split(",")[0]}`);
    });

    return dataLabels;
  };

  const getPeriodNumberForEntryIfApplicable = (entry, startEndDatesByPeriod) => {
    let periodOfEntry = "0";
    let entryDate = new Date(entry.date);
    [...startEndDatesByPeriod.keys()].forEach((period) => {
      const endDateByPeriod = startEndDatesByPeriod.get(period).endDate ? startEndDatesByPeriod.get(period).endDate : new Date();
      if (entryDate >= startEndDatesByPeriod.get(period).startDate && entryDate <= endDateByPeriod) {
        periodOfEntry = period;
      }
    });
    return periodOfEntry;
  }

  const buildPeriodicalEntryMap = (entries, startEndDatesByPeriod, office, viewOption) => {
    const periodicalEntryMap = new Map();
    if (viewOption === 'total') {
      periodicalEntryMap.set("total", new Map());
    }
    let entryPeriodNumber = "0";

    entries.forEach((entry) => {
      entryPeriodNumber = getPeriodNumberForEntryIfApplicable(entry, startEndDatesByPeriod);
      if (entryPeriodNumber !== "0") {
        if (viewOption === "by Fin") {
          if (!periodicalEntryMap.has(entry.name)) {
            periodicalEntryMap.set(entry.name, new Map([[entryPeriodNumber, ((entry.point && !isNaN(entry.point) && office.includes("Los Angeles")) ? parseInt(entry.point) : 1)]]));
          } else {
            if (periodicalEntryMap.get(entry.name).has(entryPeriodNumber)) {
                periodicalEntryMap.get(entry.name).set(entryPeriodNumber, periodicalEntryMap.get(entry.name).get(entryPeriodNumber) + ((entry.point && !isNaN(entry.point) && office.includes("Los Angeles")) ? parseInt(entry.point) : 1))
            } else {
              periodicalEntryMap.get(entry.name).set(entryPeriodNumber, ((entry.point && !isNaN(entry.point) && office.includes("Los Angeles")) ? parseInt(entry.point) : 1));
            }
          }
        } else if (viewOption === 'total') {
          if (periodicalEntryMap.get('total').has(entryPeriodNumber)) {
            periodicalEntryMap.get('total').set(entryPeriodNumber, periodicalEntryMap.get('total').get(entryPeriodNumber) + ((entry.point && !isNaN(entry.point) && office.includes("Los Angeles")) ? parseInt(entry.point) : 1));
          } else {
            periodicalEntryMap.get('total').set(entryPeriodNumber, ((entry.point && !isNaN(entry.point) && office.includes("Los Angeles")) ? parseInt(entry.point) : 1));
          }
        }
      }
    });

    [...startEndDatesByPeriod.keys()].forEach((period) => {
      [...periodicalEntryMap.keys()].forEach((key) => {
        if (!periodicalEntryMap.get(key).has(period)) {
          periodicalEntryMap.get(key).set(period, 0);
        }
      });
    });
    return periodicalEntryMap;
  };

  const buildDataSetFromPeriodicalEntryMap = (periodicalEntryMap, periodStartEndDates, byFinSelection, viewOption) => {
    let datasets = [];
    let datasetLabel = "";
    let datasetData = [];

    if ([...periodicalEntryMap.keys()].filter((name) => name === byFinSelection).length === 0) {
      byFinSelection = "all fins";
    }

    [...periodicalEntryMap.keys()].forEach((key) => {
      if (viewOption === "total" || byFinSelection === "all fins" || key === byFinSelection) {
        datasetLabel = key;
        datasetData = [];
        [...periodStartEndDates.keys()].forEach((period) => {
          datasetData.push(periodicalEntryMap.get(key).get(period));
        });
        datasets.push({
          label: datasetLabel,
          data: datasetData,
          backgroundColor: BrandThemes.coral,
          borderColor: getRandomThemeColor(),
          hoverBorderWidth: 3,
          hoverBorderColor: getRandomThemeColor(),
          lineTension: 0.3,
          fill: false,
        });
      }
    });

    return datasets;
  };

  const brandThemeKeys = Object.keys(BrandThemes).filter(key => { 
    const restrictedColors = ["white", "coral"];
    if (!restrictedColors.includes(key)) {
      return key;
    }
  });

  const getRandomThemeColor = () => {
    const randomColorName = brandThemeKeys[Math.floor(Math.random() * brandThemeKeys.length)];
    return BrandThemes[randomColorName];
  };

  const periodStartEndDates = getPeriodStartEndDates(props.numPeriodsToCreate, props.periodType, currentDate);

  const periodicalEntryMap = buildPeriodicalEntryMap(props.entries, periodStartEndDates, props.office, props.viewOption);
  console.log("periodicalEntryMap");
  console.log(periodicalEntryMap);

  const chartData = {
    labels: getDataLabels(periodStartEndDates),
    datasets: buildDataSetFromPeriodicalEntryMap(periodicalEntryMap, periodStartEndDates, props.byFinSelection, props.viewOption),
  };

  const lookBackOptions = {
    "weeks": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20, 25, 30, 35, 40, 45, 50, 52],
    "months": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  }

  const timePeriodOptions = [
    "weeks", "months"
  ]

  const viewOptions = [
    "total", "by Fin"
  ]

  const lineChartStackedOptions = [
    "stacked",
    "not stacked"
  ]

  const byFinOptions = [
    "all fins",
    ...[...periodicalEntryMap.keys()].sort((a, b) => a.toLowerCase() !== b.toLowerCase() ? a.toLowerCase() < b.toLowerCase() ? -1 : 1 : 0)
  ]

  const handlePeriodTypeSelectChange = (event, newValue) => {
    return lookBackOptions[newValue.props.value].includes(props.numPeriodsToCreate)
      ? props.handleLineChartSelectChange(event, newValue, "periodType")
      : props.handleSpecialLineChartPeriodTypeChange(event, newValue, "periodType")
  };

  return (
    <div>
      <Box display="flex" justifyContent="center">
        <Grid container>
          <Grid container>
          <Typography component="h3" style={{ paddingRight: '1%', color: 'black', fontWeight: 500, marginTop: 'auto', fontSize: '1.2rem' }}>
            {props.office.includes("Los Angeles") ? "Points over" : "Leads over"}
          </Typography>
          <Paper style={{ display: 'flex', width: 'fit-content' }}>
            <Grid item>
              <Select
                value={props.numPeriodsToCreate}
                label={"Look back"}
                onChange={(event, newValue) => props.handleLineChartSelectChange(event, newValue, "numPeriodsToCreate")}
              >
                {
                  lookBackOptions[props.periodType].map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </Select>
            </Grid>
            <Grid item>
              <Select
                value={props.periodType}
                label={"Period Type"}
                onChange={handlePeriodTypeSelectChange}
              >
                {
                  timePeriodOptions.map((period, index) => <MenuItem key={index} value={period}>{period}</MenuItem>)
                }
              </Select>
            </Grid>
            <Grid item>
              <Select
                value={props.viewOption}
                label={"View options"}
                onChange={(event, newValue) => props.handleLineChartSelectChange(event, newValue, "viewOption")} 
              >
                {
                  viewOptions.map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </Select>
            </Grid>
            <Grid item>
              <Select
                value={byFinOptions.includes(props.byFinSelection) ? props.byFinSelection : "all fins"}
                label={"By Fin Selection"}
                onChange={(event, newValue) => props.handleLineChartSelectChange(event, newValue, "byFinSelection")}
                disabled={props.viewOption === 'total' ? true : false}
              >
                {
                  byFinOptions.map((finName, index) => <MenuItem key={index} value={finName}>{finName.toString()}</MenuItem>)
                }
              </Select>
            </Grid>
            <Grid item>
              <Select
                value={props.lineChartStacked}
                label={"Line chart stacked"}
                onChange={(event, newValue) => props.handleLineChartSelectChange(event, newValue, "lineChartStacked")}
                disabled={(props.viewOption === 'total' || props.byFinSelection !== "all fins") ? true : false}
              >
                {
                  lineChartStackedOptions.map((option, index) => <MenuItem key={index} value={option}>{option.toString()}</MenuItem>)
                }
              </Select>
            </Grid>
          </Paper>
          </Grid>
          <Grid container style={{ position: "relative", margin: "auto", width: "99%", height: "90%"}}>
            <Line
              data={chartData}
              options={{
                legend: { position: "top" },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true,
                      precision: 0,
                    },
                    stacked: props.lineChartStacked === "stacked" ? true : false
                  }],
                  xAxes: [{ distribution: "series" }],
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default RenderLineChart;
